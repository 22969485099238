<template>
    <div class="detail" v-if="info">
        <div class="title">{{ "合作指南" | language }}</div>
        <div class="time">{{ info.writeAt }}</div>
        <div class="content" v-html="info.content"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: null
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.info = await this.$http.get(`/cooperation/guide`);
        }
    }
};
</script>

<style lang="less" scoped>
.detail {
    .title {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        line-height: 32px;
        margin-bottom: 10px;
    }
    .time {
        text-align: left;
        margin-bottom: 30px;
        height: 22px;
        font-size: 16px;

        color: rgba(0, 0, 0, 0.3);
        line-height: 22px;
    }
    .content {
        margin-bottom: 40px;
    }
}
</style>
<style>
.content img {
    max-width: 100%;
}
</style>
